.filters-panel {

}
.filters-panel.hidden {
  visibility: hidden;
  position: absolute !important;
  left:-3000px !important;
  height:0px !important;
  opacity: 0 !important;
  transition: none !important;
  // display: none;

  div {
    visibility: hidden;
    position: absolute !important;
    left:-3000px !important;
    height:0px !important;
    opacity: 0 !important;
    transition: none !important;
  }
}

.filter.geo-distance-no-slider {

  .rheostat {
    //opacity: 0.1;
    display: none;
  }
  .css-dqmsxc-rangeLabelsContainer{
    //opacity: 0.1;
    display: none;
  }
  input {
    border-radius: 5px;
  }
}

.filter.geo-distance-no-distance-select {
  input {
    border-radius: 5px;
  }
  // hide distance selector
  // div.css-3cr1qg-suggestionsContainer:nth-child(2) {
  //   display: none;
  // }
}

.filter.mobile-geo-distance-no-distance-select {
  input {
    border-radius: 5px;
  }
  // hide distance selector
  // div.css-3cr1qg-suggestionsContainer:nth-child(3) {
  //   display: none;
  // }
}

.filter.availabilities-filter {
  ul {
    max-height: none;
  }
  ul li {

    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    label::before {
      width: 24px;
    }

    .time-periods {
      margin-top: -10px;
      label {
        // 
      }
      select {
        max-width: 70px;
        height: 24px;
      }
    }
  }
}

section.job-offers-tabs {
  margin: 20px 0;
}

section.search-job {

  position: relative;

  .search-job-container {
      position: relative;
      background-color: #fff;
      padding: 20px;
      text-align: left;
  }

  section.main-filters {
      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      // width: 100%;
      // margin: 0;

      .filter-container {
        flex: 1;

        .geo-distance-no-slider {
          width: 98%;
        }
      }

      .actions-container {
        flex: initial;
        max-height: 40px;
        padding-left: 0px;

        .open-filters-btn {
          height: 40px;
          // span {
          //   margin-right: 0px;
          //   margin-left: 0px;
          // }
        }
      }
  }

   /* MOBILE : main-filters  */

  // section.main-filters.floating {
  //   float:right;
  // }

  section.main-filters.flex {
    margin-bottom: 0px;
    flex-direction: column;
    align-items: start !important;

    .actions-container {
      display:inline-flex;
    }
  }


  section.sort-filters {

    .sort-container {
      display: flex;
      flex-direction: row;
      // margin-top: 20px;

      .sort-selector-container {
        margin: 0 10px 0 0 ;
      }
      .sort-options-btn {
        height: 40px;
      }
    }
  }

  section.selected-filters {
    
    .selected-filters-container {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .header-heading {
      color: #424242;
      text-align: center;
      letter-spacing: 0.06rem;
      margin-bottom: 12px;
  }

  .heading-input {
      border: 0;
      height: 60px;
      padding: 0 28px;
      font-size: 18px;
      border-radius: 999em;
      box-shadow: 0 20px 30px 0 rgba(16, 36, 94, 0.2),
          inset 0 -8px 0 0 rgba(103, 117, 161, 0.08);
  }

  .list-item {
      padding: 20px 0;
      border-bottom: 1px solid #eee;
  }

  a.selected {
      background-color: yellowgreen;
  }
  a.refused {
    background-color: rosybrown;
}
  .result-stats {
      max-width: none;
      margin: 20px 0 0;
      font-size: 16px;
      margin-bottom: 5px;
      line-height: 16px;
      text-align: right;
  }


  button.btn {
    padding:5px 7px;
    cursor: pointer;
  }
  section.user {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
  section.user button {
    margin: 0 10px;
  }

  
  .stat-styles {
      max-width: none;
      margin: 30px 0 0;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 16px;
      text-align: right;
  }

  .app {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    overflow: hidden;
    grid-gap: 15px;
    padding: 10px;
  }

  section.search-results {

    .search-results-main-wrapper {

      .result-item {
        padding: 1px;
        width: 100%;
        margin: 15px 0 0;
        background: #eaeaea;
        position: relative;
        transition: all ease 0.2s;

        a.offer {
          cursor: pointer;

          article {

            padding: 5px;
            
            h2 {
              padding: 0 0 5px 0;

              span {
                padding-top: 7px;
                max-width: 90%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .description {

              padding: 7px 0 0 0;

              p {
                padding: 2px 0;
              }

              .days-schedule {
                margin-top: 5px;
              }
              span.distance {
                float: right;
              }
            }
          }
        }
        
        .action-buttons-container {
          // 
        }
      }

      .result-item.bookmarked {
        // 
      }
      .result-item.deleted {
        display: none;
      }
      .result-item.applied {
        // 
      }
    
      .result-item-key {
        display: flex;
        justify-content: space-between;
        padding: 4px;
      }
    
      .result-item-key .value {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .search-results-stats {
        font-size: 15px;
        padding: 20px 0 10px 0;
      }

    }

    .search-no-result {
      text-align: center;
      padding: 20px;
    }
  }

  pre {
    background: black;
    max-width: 300px;
    overflow: scroll;
  }

  .time-periods {
    margin-top: 0px;
  }
  .time-periods ul {
    display: flex;
    flex-wrap: wrap;
  }
  .time-periods ul li {
    width:80px;
    font-size:12px;
  }

  /******* EXPAND-COLLAPSE STYLES *******/
  .collapse {
    overflow: hidden;
    max-height: 200px;
  }

  .expand-button-container button {
    margin: 10px;
    color: #1890ff;
    border: 0;
    background: inherit;
    cursor: pointer;
  }

  .expand-button-container {
    width: 100%;
    background: linear-gradient(180deg, #eaeaea, rgba(255, 255, 255, 0.8));
  }

  /******* TOOLTIP STYLES *******/

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
    max-width: 450px;
    min-width: fit-content;
    padding: 10px;
    overflow-x: scroll;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

section.job-offer {
  hr.MuiDivider-fullWidth {
      margin: 18px 0;
  }
  h3.title {
    font-size: 1.4rem !important;
  }

  h6.label {
    margin-top: 2em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
}

/******* SEARCH FILTERS *******/

section.search-filters {
  padding: 20px;
  position: relative;

  .filter {
    min-width: 250px;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    span.label {
      display: inline-block;
      
      span.count {
        padding-left: 4px;
      }
    }
    span.label.selected {
      font-weight: bold;
    } 
    span.label.no-item {
      opacity: 0.3;
    } 

    .tooltip-info {
      max-height: 18px !important;
      max-width: 18px !important;
    }
  }

  .filter.last {
    padding-bottom: 20px;
  }

  .filter.disabled {
    // display: none;
  }

  .filter.admin {
    background: #eee;
  }

  .email-alert {
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    h3 {
      margin: 0 0 8px;
      font-size: 13px !important;
      font-weight: bold;
      color: #424242;
    }
  }

  .search-filters-bottom {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .search-filters-top {
    margin-top: 0px;
    padding-bottom: 10px;
  }
}

// NB: selectedFilters bugged but necessary to handle current filters update
section.selected-filters {
  display: none !important;
}
.init-filters-container {
  // display: none !important;
}

/******* MOBILE SEARCH FILTERS *******/

// cf: https://www.abrickis.me/labs/modals

section.search-filters.mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top:10px;
  padding-bottom: 30px;

  .inner {
    height: 82%;
    overflow-y: auto;

    .email-alert {
      border: none;

      h3 {
        font-size: 1rem !important;
      }
      span.custom-switch {
        margin-left: 3px;
      }
    }
  }
  
  .search-filters-bottom {
    height: 50px;
    border-top:1px solid #ddd;
    padding: 10px 0 0 0;
  }

  // NB: selectedFilters bugged but necessary to handle current filters update
  section.selected-filters {
    display: none !important;
  }
}

/******* DESKTOP SEARCH FILTERS & RESULTS *******/

.multi-col {

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-col {
      max-width: 350px;

      .filter {
        
        h2 {
          margin: 0px 0 12px;
          font-size: 12px;
        }
        // input[type="checkbox"] {
        //   font-size: 11px;
        // }
        label {
          font-weight: normal;
          font-size: 12px;
        }
      }

      button.btn {
        margin-top: 10px;
      }
  }

  .right-col {
      width: calc(100% - 350px);
      padding-left: 20px;
  }
}

/******* MY OFFERS *******/

.my-offers-wrapper {

  .multi-col {
    
    .left-col {
      max-width: 00px;
    }
    .right-col {
      width: 100%;
      padding-left: 0px;
  }
  }
}

.lazy-loader {
  text-align: center;
  padding: 20px 10px;
  color: #376fd0;
}

/******* OVERRIDE UI COMPONENTS *******/

.custom-switch {
  .MuiSwitch-thumb {
    box-shadow: 0px 1px 2px 0px rgb(0 0 0);
  }
}

/******* AUTO-COMPLETE *******/

.MuiAutocomplete-popper {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12) !important;
    border-radius: 6px !important;
}

  // @media (max-width: 768px) {
  //   .app {
  //     grid-template-columns: auto;
  //   }
  // }
  